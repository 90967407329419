import React from 'react'
import { Link } from 'react-router-dom'
import onestep from '../../assets/OneStepColored.svg'
import backarrow from '../../assets/backArrow.svg'


const SetRecoveryQuestions = () => {
  return (
    <div className='container'>
        <div className='back'>
            <Link to="/recovery"><img src={backarrow} alt="backarrow" /> <span>Back</span></Link>
        </div>
        <div className='signin recovery'>
        <img src={onestep} alt="onestep-logo" />
        <h2>Recovery Questions</h2>

        <ul>
            <li>Question has hundreds, if not thousands, of possible answers</li>
            <li>Question is not a question you would answer publicly (online or in person)</li>
            <li> Answer is easy for you to remember</li>
            <li>Answer cannot be researched online via Google, Facebook, LinkedIn, etc.</li>
            <li>Answer does not change over time</li>
        </ul>
        </div>

        <div className='signin createID'>
            <h2 style={{marginBottom: "50px"}}>Suggest Questions</h2>

            <div className='create_input'>
                <label>What is your mother’s maiden name?</label>
                <input type="text" placeholder='Mary Sonya' />
            </div>

            <div className='create_input'>
                <label>What is your pet name?</label>
                <input type="text" placeholder='Lux' />
            </div>

            <div className='create_input'>
                <label>Where were you born?</label>
                <input type="text" placeholder='Idina' />
            </div>

        </div>

        <p style={{color: "#777E90", textAlign: 'center', width: 'auto', margin: "80px 0 0", fontSize: "20px" }}>----- or -----</p>

        <div className='signin createID'>
            <h2 style={{marginBottom: "50px"}}>Customize your Questions</h2>

            <div className='create_input createQ'>
                <input type="text" placeholder='Question' />
                <input type="text" placeholder='Answer' />
            </div>

            <div className='create_input createQ'>
                <input type="text" placeholder='Question' />
                <input type="text" placeholder='Answer' />
            </div>

            <div className='create_input createQ'>
                <input type="text" placeholder='Question' />
                <input type="text" placeholder='Answer' />
            </div>

        
            <div className='btns'>
                <Link to="/progress"><button>Next</button></Link>
            </div>
        </div>
    </div>
  )
}

export default SetRecoveryQuestions