import React from 'react'
import { Link } from 'react-router-dom'
import onestep from '../../assets/OneStepColored.svg'
import copy from '../../assets/copy.svg'


const AccountCreated = () => {

  return (
    <div className='container'>
        <div className='signin account'>
        <img src={onestep} alt="onestep-logo" />
        <h2>Account Created Successfully</h2>
        <p>Your OneStep ID has been successfully created
        OneStep is all you need for all our services.</p>

        <div className='oneStep_details'>
            <div className='copy'>Copy <img src={copy} alt="copy" /></div>
            <div className='oneStep_detail'>
                <p>Your OneStep username</p>
                <p>Lekan Okeowo</p>
            </div>
            <div className='oneStep_detail'>
                <p>Your OneStep ID</p>
                <p>slapacoin@onestep.com</p>
            </div>
        </div>

        <div className='btns'>
            <Link to="/complete"><button>Continue</button></Link>
        </div>
        </div>
    </div>
  )
}

export default AccountCreated