import React from "react";
import facebook from "../assets/images/facebook.svg";
import instagram from "../assets/images/instagram.svg";
import snapchat from "../assets/images/snapchat.svg";
import tiktok from "../assets/images/tiktok.svg";
import twitter from "../assets/images/twitter.svg";
import linkedin from "../assets/images/linkedin.svg";
import signal from "../assets/images/signal.svg";
import telegram from "../assets/images/telegram.svg";
import viber from "../assets/images/viber.svg";
// import reddit from "../assets/images/reddit.svg";
import discord from "../assets/images/discord.svg";
import likee from "../assets/images/likee.svg";
import medium from "../assets/images/medium.svg";
import pinterest from "../assets/images/pinterest.svg";
import messenger from "../assets/images/messenger.svg";
import line from "../assets/images/line.svg";
import youtube from "../assets/images/youtube.svg";
import vk from "../assets/images/vk.svg";

const SocialLinks = () => {
  return (
    <div className="social__links">
      <div className="level__one">
        <a href="_">
          <img src={facebook} alt="facebook" />
        </a>
        <a
          href="https://www.instagram.com/ultainfinityG_met"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagram} alt="instagram" />
        </a>
        <a href="_">
          <img src={snapchat} alt="snapchat" />
        </a>
        <a
          href="https://www.tiktok.com/@onestepid"
          target="_blank"
          rel="noreferrer"
        >
          <img src={tiktok} alt="tiktok" />
        </a>
        <a
          href="https://twitter.com/onestepID"
          target="_blank"
          rel="noreferrer"
        >
          <img src={twitter} alt="twitter" />
        </a>
        <a
          href="https://www.linkedin.com/in/ultainfinitywealthlaunchpad"
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkedin} alt="linkedin" />
        </a>
        <a href="_">
          <img src={viber} alt="viber" />
        </a>
        <a
          href="https://medium.com/@onestepid"
          target="_blank"
          rel="noreferrer"
        >
          <img src={medium} alt="medium" />
        </a>
        <a
          href="https://discord.gg/jGuPTFHGRZ"
          target="_blank"
          rel="noreferrer"
        >
          <img src={discord} alt="discord" />
        </a>
        <a
          href="http://www.pinterest.com/onestepid"
          target="_blank"
          rel="noreferrer"
        >
          <img src={pinterest} alt="pinterest" />
        </a>
      </div>
      <div className="level__one">
        <img src="/images/quora.svg" alt="quora" />
        <a
          href="https://youtube.com/channel/UC1NBAQW2Z1exLI38kDrrv5A"
          target="_blank"
          rel="noreferrer"
        >
          <img src={youtube} alt="youtube" />
        </a>
        <a href="_">
          <img src={telegram} alt="telegram" />
        </a>
        <a href="_">
          <img src={likee} alt="likee" />
        </a>
        <a href="_">
          <img src={vk} alt="vk" />
        </a>
        <a href="http://m.me/109020541664806" target="_blank" rel="noreferrer">
          <img src={messenger} alt="messenger" />
        </a>
        <a href="_">
          <img src={viber} alt="viber" />
        </a>
        <a href="_">
          <img src={line} alt="line" />
        </a>
        <a href="_">
          <img src={viber} alt="viber" />
        </a>
        <a href="https://signal.org/install" target="_blank" rel="noreferrer">
          <img src={signal} alt="signal" />
        </a>
      </div>
    </div>
  );
};

export default SocialLinks;
