import React from "react";

import PhaseOne from "../../assets/images/Roadmaps/phase__one.png";
import PhaseTwo from "../../assets/images/Roadmaps/phase__two.svg";
import PhaseThree from "../../assets/images/Roadmaps/phase__three.svg";
import PhaseFour from "../../assets/images/Roadmaps/phase__four.svg";
import PhaseFive from "../../assets/images/Roadmaps/phase__five.svg";
import PhaseSix from "../../assets/images/Roadmaps/phase__six.svg";
import ActivePhase from '../../assets/images/Roadmaps/active__phase.svg'

const Release = () => {
    return (
        <div className="roadmap__release">
            <div className="release__head">
                <div></div>
                <h5>Release</h5>
            </div>
            <div className="release__text">
                <p>Ultainfinity Global Group's Quarterly timeline showcasing the pathway to the creation of the greatest crypto wealth initiative in the world. </p>
            </div>

            {/* Release Phases */}
            <div className="release__phases">
                <div className="release__phase one">
                    {" "}
                    <img src={PhaseOne} alt="PhaseOne" />
                    <img src={ActivePhase} className="active__phase" alt="Active Phase" />
                    <p>Q2/22</p>{" "}
                </div>
                <div className="release__phase two">
                    {" "}
                    <img src={PhaseTwo} alt="PhaseTwo" /> <p>Q3/22</p>{" "}
                </div>
                <div className="release__phase three">
                    {" "}
                    <img src={PhaseThree} alt="PhaseThree" /> <p>Q4/22</p>{" "}
                </div>
                <div className="release__phase four">
                    {" "}
                    <img src={PhaseFour} alt="PhaseFour" /> <p>Q1/23</p>{" "}
                </div>
                <div className="release__phase five">
                    {" "}
                    <img src={PhaseFive} alt="PhaseFive" /> <p>Q2/23</p>{" "}
                </div>
                <div className="release__phase six">
                    {" "}
                    <img src={PhaseSix} alt="PhaseSix" /> <p>Q3/23</p>{" "}
                </div>
            </div>
        </div>
    );
};

export default Release;
