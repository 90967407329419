import React from 'react'
import Footer from '../UI/Footer'
import Sidebar from './Sidebar'
import {Outlet } from 'react-router-dom'

const Profile = () => {
  return (
    <>
        <div className='profile'>
            <Sidebar />
            <div>
                <Outlet />
            </div>
        </div>
        <Footer />
    </>
  )
}

export default Profile