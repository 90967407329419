import React from "react";
import { Link } from "react-router-dom";
import onestep from "../../assets/OneStepColored.svg";
import backarrow from "../../assets/backArrow.svg";
import sms from "../../assets/sms.svg";
import mes from "../../assets/mes.svg";

const OneStepIDRecovery = () => {
  return (
    <div className="container">
      <div className="back">
        <Link to="/">
          <img src={backarrow} alt="backarrow" /> <span>Back</span>
        </Link>
      </div>
      <div className="signin recoveryID">
        <img src={onestep} alt="onestep-logo" />
        <h2>OneStep ID Recovery</h2>
        <p>Please select preferred means of recovery</p>

        <div className="recovery_method">
          <p style={{ textAlign: "left" }}>Recover OneStep using</p>
          <Link to="/onestepmessenger"><button className="recovery_btn">
            <img src={mes} alt="mes" />
            <span>Messenger</span>
          </button></Link>
          <Link to="/verify_recovery"><button className="recovery_btn">
            <img src={sms} alt="sms" />
            <span>SMS</span>
          </button></Link>
        </div>
      </div>
    </div>
  );
};

export default OneStepIDRecovery;
