import React from 'react'
import Details from './Details'
import onestep from '../assets/OneStep.svg'
import Footer from './UI/Footer'
import { Link } from 'react-router-dom'

const Hero = () => {
  return (
    <>
    <div className='container hero'>
      <img src={onestep} alt="onestep-logo" />
      <h2>OneStep Sign up and Identification</h2>
      <p>A new dawn and invention for sign-ups and identification, worldwide.
      For use on mobile, web applications and desktop platforms.
      </p>
      <Link to="/createID"><button>Create OneStep ID</button></Link>
    </div>
    <Details />
    <Footer />
    </>
  )
}

export default Hero