import React from "react";
import "./roadmap__styles.css";
import LinearDivider from '../../assets/images/Roadmaps/linear__divider.svg'
import ScheduleBorder from '../../assets/images/Roadmaps/schedule__border.svg'

const Schedule = () => {
    return (
        <div className="roadmap__schedule">
            <div className="release__head">
                <div></div>
                <h5>Schedule</h5>
            </div>
            <div className="schedule__text">
                <p>
                The Ultainfinity roadmaps are a representation of the different projects Undertaken by the Ultainfinity Global Group.
                </p>
                <p>
                The Quaterly representation shows when it was conceived, the present and the future of each of the projects.
                </p>
                <p>
                The roadmaps have been carefully drafted to reflect the true vision of the Ultainfinity Group, however circumstances may require that dates of implementation may change, however, the feats to be achieved is not subject to change.
                </p>
                <p>
                Users are therefore advised to check the roadmaps from time to time to keep up with Ultainfinity projects
                </p>
            </div>

            <div className="schedules">
                <div className="schedule__top">
                    <img src={ScheduleBorder} className="border__one"  alt="Schedule Border"/>
                    <div className="schedule one">
                        <h5>Q2/22</h5>
                        <p>Global PreSales</p>
                        <p>ULTAINFINITY TV</p>
                        <p>UTTA TV CHANNELS</p>
                        <p>ULTAINFINITY G-MET</p>
                        <p>OneStep</p>
                        <p>Instant CreditLine</p>
                    </div>
                    <div className="schedule two">
                        <h5>Q3/22</h5>
                        <p>PreMarket Exchange</p>
                        <p>SLAPA Series of Tokens & Assets</p>
                        <p>SACA By DASA</p>
                        <p>SLAPA Model & Systems Papers</p>
                    </div>
                    <div className="schedule three">
                        <h5>Q4/22</h5>
                        <p>ULTAINFINITY WEALTH</p>
                        <p>ULTAINFINITY EXCHANGE</p>
                    </div>
                </div>
                <img src={LinearDivider} alt="Linear Divider" />
                <div className="schedule__bottom">
                    {/* <div className="border"></div> */}
                    <div className="schedule schedule__one">
                        <h5>UTTA TOKEN</h5>
                        <p>$0.0001</p>
                        <p>to</p>
                        <p>$5.814</p>
                        <button className="bottom__paragraph buy__token">Buy Token</button>
                    </div>
                    
                    <div className="schedule">
                        <h5>DIVIT TOKEN</h5>
                        <p>$0.0001</p>
                        <p>to</p>
                        <p>$0.5814</p>
                        <button className="bottom__paragraph buy__token">Buy Token</button>
                    </div>
                    <div className="schedule">
                        <h5>SLAPA TOKEN</h5>
                        <p>$0.0001</p>
                        <p>to</p>
                        <p>$1</p>
                        <button className="bottom__paragraph coming__soon">Coming Soon</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Schedule;
