import React, { useState, useEffect } from "react";
import onestep from "../../assets/OneStepColored.svg";
import backarrow from "../../assets/backArrow.svg";
import ReactInputVerificationCode from "react-input-verification-code";
import { Link, useNavigate } from "react-router-dom";
import { PinInput } from "react-input-pin-code";
import { toast } from "react-toastify";
import axios from "axios";
import ReactLoading from "react-loading";

const VerifyID = ({ details, response }) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [values, setValues] = React.useState(["", "", "", "", "", ""]);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [token, setToken] = useState(Object.keys(response).length > 0 ? response?.data.otpToken : "");
  const navigate = useNavigate();

  // console.log(values)

  const handleVerification = async () => {
    // console.log(code);
    if (values.join("") === "") {
      // return;
      toast.error("Incomplete code");
      return;
    }
    if (values.join("").length < 6) {
      toast.error("Invalid code");
      return;
    }

    setVerifyLoading(true);
    try {
      const response = await axios.post(
        "https://api.ultainfinity.com/v1/users/user-account/v2/otp/verify",
        {
          token: values.join(""),
        }
      );
      console.log(response);
      setVerifyLoading(false);
      toast.success(response.data.message);
      navigate("/signin");
    } catch (error) {
      setVerifyLoading(false);
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
    // console.log(values.join(""))
  };

  const handleResendToken = () => {};

  return (
    <div className="container">
      <div className="back">
        <Link to="/createID">
          <img src={backarrow} alt="backarrow" /> <span>Back</span>
        </Link>
      </div>
      <div className="signin">
        <img src={onestep} alt="onestep-logo" />
        <h2>Verify Details</h2>
        <p>
          We have sent an OTP code to {details.phone} <br /> Input the OTP code
          to complete your verification
        </p>
        <PinInput
          values={values}
          onChange={(value, index, values) => setValues(values)}
          type="text"
        />

        <div className="btns">
          <button onClick={handleVerification}>
            {verifyLoading ? (
              <ReactLoading type="spin" width={30} height={30} color="#fff" />
            ) : (
              "Verify ID"
            )}
          </button>
        </div>

        {error && <small>{error}</small>}

        <div className="btns">
          <button id="recover">
            {resendLoading ? (
              <ReactLoading
                type="spin"
                width={30}
                height={30}
                color="#9757D7"
              />
            ) : (
              "Resend Code"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyID;
