import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import profile from "../../assets/profile.svg"

const Sidebar = () => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div className='sidebar'>
        <img src={profile} alt="profile_pic" />
        <p id="name"><strong>John Doe</strong></p>
        <p id="id">ID239892380</p>

        <Link to="/profile"><p className={` ${activeTab === 0 ? "active_tab" : ""}`} data-tab="0" onClick={(e) => setActiveTab(0)}>Sign-In and Security</p></Link>
        <Link to="/profile/personal"><p className={` ${activeTab === 1 ? "active_tab" : ""}`} data-tab="1" onClick={(e) => setActiveTab(1)}>Personal Information</p></Link>
        <Link to="/profile/contact"><p className={` ${activeTab === 2 ? "active_tab" : ""}`} data-tab="2" onClick={(e) => setActiveTab(2)}>Contact Book</p></Link>
        <Link to="/profile/socials"><p className={` ${activeTab === 3 ? "active_tab" : ""}`} data-tab="3" onClick={(e) => setActiveTab(3)}>Social Media Accounts</p></Link>
    </div>
  )
}

export default Sidebar