import React, { Fragment } from "react";
// import Header from "../../components/Header";
import Hero from "../../components/privacy/Hero";
import "./Privacy.css";

const Privacy = () => {
    return (
        <Fragment>
            {/* <Header /> */}
            <div className="privary__section">
                <Hero />
            </div>
        </Fragment>
    );
};

export default Privacy;
