import React from 'react'
import name from '../../assets/profile/name.svg'
import email from '../../assets/profile/email.svg'
import country from '../../assets/profile/country.svg'
import language from '../../assets/profile/language.svg'
import level from '../../assets/profile/level.svg'

const PersonalInfo = () => {
  return (
    <div className='profile_section'>
      <div className='profile_top'>
        <div style={{width: "100%", display: 'flex', justifyContent: 'right', alignItems: "right"}}>
          <button>Edit</button>
        </div>
        <h3>Personal Information</h3>
      </div>
      <p>Manage your personal information, including phone numbers and email addresses where you can be reached.</p>

      <div className='card_container'>
        <div className='card'>
          <div>
            <h4>Name</h4>
            <p>Breanner Schinner</p>
          </div>
          <img src={name} alt="web" />
        </div>

        <div className='card'>
          <div>
            <h4>Country/Region</h4>
            <p>United States</p>
          </div>
          <img src={country} alt="web" />
        </div>

        <div className='card'>
          <div>
            <h4>Email Address</h4>
            <p>Breanner@gmail.com</p>
          </div>
          <img src={email} alt="web" />
        </div>

        <div className='card'>
          <div>
            <h4>Level/Region</h4>
            <p>Level 2 verified</p>
          </div>
          <img src={level} alt="web" />
        </div>

        <div className='card'>
          <div>
            <h4>Language</h4>
            <p>English</p>
          </div>
          <img src={language} alt="web" />
        </div>
      </div>
    </div>
  )
}

export default PersonalInfo