import React from 'react'
import { Link } from 'react-router-dom'
import onestep from '../../assets/OneStepColored.svg'
import backarrow from '../../assets/backArrow.svg'


const RecoveryQuestions = () => {

  return (
    <div className='container'>
        <div className='back'>
            <Link to="/"><img src={backarrow} alt="backarrow" /> <span>Back</span></Link>
        </div>
        <div className='signin recovery'>
        <img src={onestep} alt="onestep-logo" />
        <h2>Recovery Questions</h2>

        <ul>
            <li>Question has hundreds, if not thousands, of possible answers</li>
            <li>Question is not a question you would answer publicly (online or in person)</li>
            <li> Answer is easy for you to remember</li>
            <li>Answer cannot be researched online via Google, Facebook, LinkedIn, etc.</li>
            <li>Answer does not change over time</li>
        </ul>

        <div className='btns'>
            <Link to="/set-recovery"><button>Continue</button></Link>
        </div>
        </div>
    </div>
  )
}

export default RecoveryQuestions