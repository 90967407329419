import React from 'react'
import { Link } from 'react-router-dom'
import onestep from '../../assets/OneStepColored.svg'
import backarrow from '../../assets/backArrow.svg'


const About = () => {

  return (
    <div className='container'>
        <div className='back'>
            <Link to="/"><img src={backarrow} alt="backarrow" /> <span>Back</span></Link>
        </div>
        <div className='signin about'>
        <img src={onestep} alt="onestep-logo" />
        <h2>What is OneStep ID?</h2>

        
        <p>Pop ups irritate users as it ultimately tells on user experience of platforms with such. This and many more necessitated the creation of an all encompassing solution by Mr M Michael for Ultainfinity platforms and its affiliates within the entire ecosystem of Ultainfinity Global Group.</p>
        <p>OneStep as the name implies is a solution to multiple unnecessary procedures that can be accomplished in just a single step!</p>
        <p>This solution helps users to login without the use of emails or the traditionally  frustrating signup systems. 
        It works with a simple OneStep ID which shares some characteristic similarities with the Apple ID with more benefits and perks that come with it unlike the Apple ID.</p>

        <div className='btns'>
            <Link to="/createID"><button>Sign Up</button></Link>
            <Link to="/signin" id="sign_btn"><button>Sign In</button></Link>
        </div>
        </div>
    </div>
  )
}

export default About