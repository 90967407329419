import React from "react";
import "./roadmap__styles.css";
import HorLine from "../../assets/images/Roadmaps/hor__line.svg";
// import VerLine from "../../assets/images/Roadmaps/ver__line.svg";

const DevelopmentStages = () => {
    return (
        <div className="development__stages">
            <div className="development__stage">
                {/* <img src={VerLine} className="ver__line one" alt="Ver Line" /> */}
                <div className="inner__stage">
                    <div className="release__head">
                        <div></div>
                        <h5>Development Stage Phase I (4th Quarter 2020)</h5>
                    </div>
                    <ul>
                        <li>
                            <span>The idea conceptualisation for a global series of blockbuster blockchain-based crypto projects to transform the sector and impact a great swathe of the world’s population for generations to come</span>
                        </li>
                        <li>
                            Identifying the wealth transfer working concept. <br /> Built to engender working elements to ensure investors get multiple folds in returns.
                        </li>
                        <li>Identifying the several working parts of UGG( Ultainfinity Group, SLAPA Group and Sumra Group)</li>
                        <li>Projects were listed to aid the Wealth creation initiative;</li>
                        <li>
                            Ultainfinity Wealth LaunchPad <br /> The hub of all Ultainfinity wealth creation tools.
                        </li>
                        <li>
                            GRIP300
                            <br /> Ultainfinity Influencers dashboard
                        </li>
                        <li>
                            OneStep ID <br /> A novel invention to breakdown complex registration procedures and a unique ID native to all users of the Ultainfinity platform and affiliates across the globe
                        </li>
                        <li>Instant CreditLine</li>
                        <li>
                            Utta Token <br /> Our wealth token built on the Solana blockchain
                        </li>
                        <li>
                            Divit Token <br /> Also, a wealth creation token built on the Solana blockchain
                        </li>
                        <li>
                            Slapa coin <br /> Ultainfinity's unique coin with its specialised blockchain
                        </li>
                        <li>
                            Synthetic Slapa token <br /> Our token on the Slapa model
                        </li>
                        <li>
                            Ultainfinity exchange <br /> This Ultainfinity unique exchange where popular coins and Ultainfinity tokens can be traded
                        </li>
                        <li>
                            Ultainfinity Wallet <br /> Our unique crypto wallet which supports the Solana blockchain.
                        </li>
                        <li>Researching and contacting different field experts to carry out feasibility studies on all wealth creation tools Inder UGG</li>
                        <li>Contact developers and top blockchain research institutions to test-run and confirm the feasibility of the proposed coin and token protocol</li>
                        <li>Sumra Projects Sumra ID, Sumra Market place,Sumrachat, Sumrameet, Sumra Wallet [Fiat FinTech]</li>
                    </ul>
                </div>
            <img src={HorLine} className="hor__line" alt="Hor line" />
            </div>
            <div className="development__stage">
                <div className="inner__stage">
                    <div className="release__head">
                        <div></div>
                        <h5>Development Stage Phase II (1st and 2nd Quarter 2021)</h5>
                    </div>
                    <ul>
                        <li>
                            Ultainfinity Wealth LaunchPad <br /> Conceptualization The start of the world's greatest wealth crypto wealth creation innovation.
                        </li>
                        <li>
                            Coin and Tokens Conceptualization( Slapa Coin, Utta token and Divit token) <br /> The Ultainfinity team created a fail-proof token that would ensure the primary aim of Ultainfinity is achieved, and that's simply
                            bridging the wealth gap in the world.
                        </li>
                        <li>
                            Developer team assembly <br /> The coming together of the brightest minds in the crypto industry set the ball rolling for wealth creation projects.
                        </li>
                        <li>
                            Token Build <br /> Designed on arguably the fastest blockchain in the world
                        </li>
                        <li>
                            Roadmap design <br /> Carefully drafted attainable feats for the UGG
                        </li>
                        <li>
                            Whitepaper design(UGG) <br /> An insight into what the UGG is all about. Our whitepaper is showcases an objective representation of what the UGG stands for and its projects
                        </li>
                        <li>
                            Website design( Ultainfinity Wealth Launchpad and UGG website design) <br /> Also, an objective representation of what the Ultainfinity wealth LaunchPad is all about is contained in its whitepaper.
                        </li>
                    </ul>
                </div>
            <img src={HorLine} className="hor__line" alt="Hor line" />
            </div>
            <div className="development__stage">
                <div className="inner__stage">
                    <div className="release__head">
                        <div></div>
                        <h5>Development Stage phase III (3rd and 4th Quarter of 2021)</h5>
                    </div>
                    <ul>
                        <li>Grip300 platform build</li>
                        <li>OneStep ID interphase build</li>
                        <li>OneStep ContactBook design</li>
                        <li>OneStep ContactCash design</li>
                        <li>Whitepaper Development ( Slapa coin, Utta token and Divit token)</li>
                    </ul>
                </div>
            <img src={HorLine} className="hor__line" alt="Hor line" />
            </div>
            <div className="development__stage">
                <div className="inner__stage">
                    <div className="release__head">
                        <div></div>
                        <h5>Getting Started: (1st Quarter 2022)</h5>
                    </div>
                    <ul>
                        <li>
                            Creation of multiple Social media platforms for all UGG affiliated projects We pride ourselves as a global project which is not restricted to any region of the world. We are everywhere, and the same goes for our
                            social media presence. Name any popular social media platform, and Ultainfinity is there.
                        </li>
                        <li>
                            Reaching out to global influencers phase 1 <br /> Here we commence reaching out to reputable influencers who are to promote our diverse projects.
                        </li>
                        <li>
                            Deploying Ultainfinity tokens on testnets <br /> At this stage, we are putting the finishing touches to our tokens to run seamlessly upon launch
                        </li>
                        <li>Consulting experts to review all projects on UGG</li>
                    </ul>
                </div>
            <img src={HorLine} className="hor__line" alt="Hor line" />
            </div>
            <div className="development__stage">
                <div className="inner__stage">
                    <div className="release__head">
                        <div></div>
                        <h5>Getting Started II:(2nd Quarter 2022)</h5>
                    </div>
                    <ul>
                        <li>Official Website Launch for a designated project(s)</li>
                        <li>Official Whitepaper launch for selected project(s)</li>
                        <li>Marketing phase launch.</li>
                        <li>White list registration for designated token launch</li>
                        <li>Ultainfinity wealth LanchPad official Launch</li>
                        <li>Ultainfinity Instant CreditLine</li>
                        <li>Ultainfinity OneStep ID launch</li>
                    </ul>
                </div>
            <img src={HorLine} className="hor__line" alt="Hor line" />
            </div>
            <div className="development__stage">
                <div className="inner__stage">
                    <div className="release__head">
                        <div></div>
                        <h5>Community Growth; social media and Influencer’s marketing (2nd Quarter 2022)</h5>
                    </div>
                    <ul>
                        <li>Partnering with Discord, Instagram, Telegram, Facebook, Twitter, and LinkedIn Crypto Influencers phase II</li>
                        <li>Designating Airdrops and rewards to various creative activities on all social media platforms.</li>
                        <li>
                            Appointment of Global Ambassadors for Ultainfinity tokens and projects <br /> These are partner representatives of what the UGG stand for. They will be ambassadors of the greatest wealth creation initiative in
                            the world.
                        </li>
                    </ul>
                </div>
            <img src={HorLine} className="hor__line" alt="Hor line" />
            </div>
            <div className="development__stage">
                <div className="inner__stage">
                    <div className="release__head">
                        <div></div>
                        <h5>Getting Started II:(2nd Quarter 2022)</h5>
                    </div>
                    <ul>
                        <li>Official Website Launch for a designated project(s)</li>
                        <li>Official Whitepaper launch for selected project(s)</li>
                        <li>Marketing phase launch.</li>
                        <li>White list registration for designated token launch</li>
                        <li>Ultainfinity wealth LanchPad official Launch</li>
                        <li>Ultainfinity Instant CreditLine</li>
                        <li>Ultainfinity OneStep ID launch</li>
                    </ul>
                </div>
            <img src={HorLine} className="hor__line" alt="Hor line" />
            </div>
            <div className="development__stage">
                <div className="inner__stage">
                    <div className="release__head">
                        <div></div>
                        <h5>Community Growth; social media and Influencer’s marketing (2nd Quarter 2022)</h5>
                    </div>
                    <ul>
                        <li>Partnering with Discord, Instagram, Telegram, Facebook, Twitter, and LinkedIn Crypto Influencers phase II</li>
                        <li>Designating Airdrops and rewards to various creative activities on all social media platforms.</li>
                        <li>
                            Appointment of Global Ambassadors for Ultainfinity tokens and projects <br /> These are partner representatives of what the UGG stand for. They will be ambassadors of the greatest wealth creation initiative in
                            the world.
                        </li>
                    </ul>
                </div>
            <img src={HorLine} className="hor__line" alt="Hor line" />
            </div>
            <div className="development__stage">
                <div className="inner__stage">
                    <div className="release__head">
                        <div></div>
                        <h5>PreSale Launch for designated token(s) (2nd Quarter 2022) </h5>
                    </div>
                    <ul>
                        <li>
                            Internal Token Launch for Whitelisted members <br /> Investors in the Ultafinity tokens will have the opportunity to register for the whitelist and be able to buy for the least price our tokens will ever be. A
                            rare opportunity that would never return once the token takes off.
                        </li>
                        <li>
                            Premarket exchange Launch <br /> This is another benefit early investors will enjoy. As a whitelisted member, you get to participate in the Premarket exchange where you can buy and sell your tokens.
                        </li>
                    </ul>
                </div>
            <img src={HorLine} className="hor__line" alt="Hor line" />
            </div>
            <div className="development__stage">
                <div className="inner__stage">
                    <div className="release__head">
                        <div></div>
                        <h5>Future Use cases and applications (3rd Quarter 2022 - future)</h5>
                    </div>
                    <ul>
                        <li>Public Sale Launch of designated token(s)</li>
                        <li>Official Launch of Ultainfinity exchange</li>
                        <li>Continuing social community growth</li>
                        <li>Launch of Ultainfinity Global exchange platform with NFT market place</li>
                        <li>Launch of Ultainfinity mobile Crypto wallet</li>
                        <li>Slapa LP token Launch</li>
                        <li>SUMRA ID Launch</li>
                    </ul>
                </div>
            <img src={HorLine} className="hor__line" alt="Hor line" />
            </div>
        </div>
    );
};

export default DevelopmentStages;
