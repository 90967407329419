import React, {useEffect} from "react";
import Footer from "../../components/UI/Footer";
import Hero from "../../components/RoadmapComponents/Hero";
// import RoadmapHeader from '../../components/RoadmapComponents/index'
import Schedule from '../../components/RoadmapComponents/Schedule'
import Stages from '../../components/RoadmapComponents/Stages'
import RoadmapComponent from '../../components/RoadmapComponents/Roadmap'
import DevelopmentStages from "../../components/RoadmapComponents/DevelopmentStages";

const Roadmap = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            {/* <RoadmapHeader /> */}
            <Hero />
            <Schedule />
            <DevelopmentStages />
            <Stages />
            <RoadmapComponent />
            <Footer />
        </div>
    )
}

export default Roadmap