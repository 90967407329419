import React, { Fragment, useEffect } from "react";
import Footer from "../../components/UI/Footer";
// import Header from "../../components/Header";
import "./TermsAndConditions.css";

const TermsAndConditions = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    },[])
    return (
        <Fragment>
            {/* <Header /> */}
            <div className="terms__hero">
                <i className="bx bxs-heart"></i>
                <h3>Terms & Condition</h3>
                <p>
                    Welcome to Ultainfinity Wealth LaunchPad Terms and Conditions section. You will be getting intimate with our terms and how they apply to you. This will give you an idea of how best to get along.
                    <br />
                    <br />
                    It is important you read through this document carefully to fully grasp our concept and properly understand our service offerings and operations for your account. This will arm you properly with the necessary information
                    moving forward.
                </p>
            </div>

            <div className="terms__about">
                <img src="/images/ulta__logo.svg" alt="Ulta logo" />
                <h3>About Ultainfinity platform</h3>
                <p>
                    Ultainfinity Wealth LaunchPad is part of the ecosystem created by the Ultainfinity Global Group, a conglomeration of diverse and fast-growing financial and technology companies based in the UK, USA, and European Union.
                    Ultainfinity Global Group is involved in the launch of cryptocurrencies of the future and in a myriad of wealth-creating platforms. Ultainfinity Wealth LaunchPad is also affiliated with OneStep which mainly comprises
                    OneStep ID, OneStep ContactBook, OneStep ContactCash, and the optional OneStep GlobalConnect.
                </p>

                <div className="terms__service">
                    <h3>Our Services and Mode of operation</h3>
                    <p>
                        Ultainfinity Wealth LaunchPad’s platform provides wealth-creating suites this starts with the whitelist, which can only be accessed after proper registration and verification. The whitelist allows you to buy and make
                        either fractional or full purchase deposits with fiat or chosen cryptocurrencies to purchase Ultainfinity’s cryptocurrencies: UTTA and DIVIT, at exceptionally low costs. This cannot be gotten somewhere else outside
                        our ecosystem. <br />
                        There is also an opportunity for a 50% of bonus on cryptocurrencies on the occasion you make a bigger purchase. After making additional purchases you are eligible to obtain instant credit line which boosts and
                        increases the return for each of the cryptocurrencies purchased. Following the Whitelist and PreSales for UTTA and DIVIT; you are able to buy SLAPA token.
                    </p>
                </div>

                <div className="service__cards">
                    <div className="service__card">
                        <img src="/images/Lock-circle6.svg" alt="Lock circle" />
                        <h5>Referral And Rewards</h5>
                        <p>
                            Know that the referrals, rewards, and ContactBook payments are owned and ultimately paid out by DASA Group and are commercially licensed to Ultainfinity Global Group for use on all its platforms and related and
                            affiliated websites, products, and services. <br /> Referrals, rewards, and ContactBook can be withdrawn at any time. The adjudication of Ultainfinity or its affiliates is final and cannot be appealed.
                        </p>
                    </div>
                    <div className="service__card">
                        <img src="/images/Lock-circle5.svg" alt="Lock circle" />
                        <h5>Referral And Rewards</h5>
                        <p>
                            Ultainfinity Wealth LaunchPad can at any point in time, reexamine these terms and conditions by updating these terms and conditions. You then agree that you will be bound by the subsequent changes and agree to
                            review the terms and conditions at regular intervals for any possible change.
                        </p>
                    </div>
                    <div className="service__card">
                        <img src="/images/Lock-circle4.svg" alt="Lock circle" />
                        <h5>Eligibility</h5>
                        <p>
                            To be a user, you have to meet the following criteria: <br /> -You must be at least 18 years of age at the time of creating a new account. <br /> -You must create an account for individuals and not businesses.{" "}
                            <br /> -You must avail yourself to go through the verification process
                        </p>
                    </div>
                    <div className="service__card">
                        <img src="/images/Lock-circle3.svg" alt="Lock circle" />
                        <h5>Termination of account</h5>
                        <p>
                            You agree to have your account terminated immediately at any time and anywhere if you engage in nonconformable acts, viz. <br />
                            -Circumventing our laid down protocols <br />
                            -Involvement in any fraudulent activity of any sort is prohibited by the government regulatory rules. <br />
                            -Harassment of our employees <br />
                            -Debt Collection <br />
                            -False information <br />
                            -Reproducing our contents and services in any way or manner
                        </p>
                    </div>
                    <div className="service__card">
                        <img src="/images/Lock-circle2.svg" alt="Lock circle" />
                        <h5>Intellectual Property</h5>
                        <p>
                            All the intellectual property is owned byUltainfinity Wealth LaunchPad, be it content or a logo. They should not be used, imitated, copied, or reproduced without earlier consent in a written format. <br />
                            Nothing gives you the right to use our trademarks except for the right to use our services in their different formats.
                        </p>
                    </div>
                    <div className="service__card">
                        <img src="/images/Lock-circle1.svg" alt="Lock circle" />
                        <h5>Important Notice </h5>
                        <p>It is important to note that trading in cryptocurrencies and, in particular, synthetic assets comes with risks. You can lose all your investments.</p>
                    </div>
                </div>

                <div className="indemnity">
                    <img src="/images/Lock-circle0.svg" alt="Lock circle" />
                    <h5>Indemnity</h5>
                    <p>
                        Ultainfinity Wealth LaunchPad and all its affiliates, officers, directors, agents, partners, employees, licensors, and representatives will be indemnified and held harmless by you in any case and claim of demands,
                        proceedings, suits, losses, damages, costs, including expenses, and reasonable payments relating to or arising from your account concerning Ultainfinity Wealth LaunchPad’s services. Also, by any act of error by
                        omission committed while using your account or the account of another user, Although this is not constrained to, things relating to off base, inadequate, or deceiving data; libel; invasion of protection; encroachment
                        of copyright, exchange title, trademark, benefit stamp, or other intellectual property; any imperfect item or any damage or harm to individual or property caused by any items sold or something else dispersed through
                        or in association with the Benefit; or infringement of any pertinent law.
                    </p>
                </div>
            </div>

            <div className="terms__rights">
                <div className="rights__top">
                    <img src="/images/Lock-circle8.svg" alt="Lock circle" />
                    <h3>Rights</h3>
                </div>
                <div className="right__cards">
                    <div className="right__card">
                        <h5>Right of access</h5>
                        <p>You have the right to obtain confirmation about your data that is being processed and to obtain a copy of it. You also have the right to know what exactly is being processed.</p>
                    </div>
                    <div className="right__card">
                        <h5>Right of rectification</h5>
                        <p>You have the right to request that your data information which is incorrect be rectified. You have the right to change your personal information at any moment.</p>
                    </div>
                    <div className="right__card">
                        <h5>Right to delete</h5>
                        <p>When necessary, you can request your data to be deleted.</p>
                    </div>
                    <div className="right__card">
                        <h5>Right to object</h5>
                        <p>You have the right to object to the processing of your information for peculiar reasons.</p>
                    </div>
                    <div className="right__card">
                        <h5>Right to limit the data processing</h5>
                        <p>
                            You have the right to reduce the processing of your data in some circumstances.
                            <br /> <br />
                            You have the right to request your data to be delivered in a structured and commonly consumed way.
                        </p>
                    </div>
                    <div className="right__card">
                        <h5>Right to withdraw consent</h5>
                        <p>
                            You have the right to withdraw your consent for any process requiring your consent. <br /> <br /> You have the right to properly define the instructions concerning the retention, communication, and deletion of
                            your data in case of your demise. <br /> <br /> You have the right to complain to the relevant Ultainfinity Wealth LaunchPad personnel whenever the need arises.
                        </p>
                    </div>
                </div>
            </div>

            <div className="terms__aceeptance">
                <h3>Acceptance of terms and conditions</h3>
                <p>
                    Our terms and conditions extend to your adherence to our terms, provisos, and instructions expressed by us or our affiliates as to the necessary steps you are required to take for any action or activity in relation to
                    any products or services, or facilities described on the related website, dashboard, tutorial, referenced document, guidance, and brochure. And by using Ultainfinity Wealth LaunchPad and the content, you agree to follow
                    and be bound by these terms and conditions, created by us. By using Ultainfinity Wealth LaunchPad, you're also consenting to having your individual information exchanged and handled by OneStep and its affiliates.
                </p>

                <div className="terms__disclaimer">
                    <h3>Disclaimer</h3>
                    <p>Ultainfinity Wealth LaunchPad’s service is given on an "as is" and "as available" premise. Simply put, our services are given based on their availability. And, to the greatest extent permitted by applicable law, Ultainfinity Wealth LaunchPad and its members expressly disclaim any warranties, express or implied, concerning the service, including, but not limited to, implied guarantees of merchantability, fitness for a particular purpose, and non-infringement. Neither Ultainfinity Wealth LaunchPad nor affiliates guarantee the accuracy, convenience, completeness, or usefulness of our services. You agree to utilize the service at your own risk. ULTAINFINITY WEALTH LAUNCHPAD doesn't endorse third-party content. ULTAINFINITY WEALTH LAUNCHPAD AND ITS AFFILIATES WILL NOT BE LIABLE FOR ANY INDIRECT, DIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, LOSSES OF REVENUE, INCOME, GOODWILL, USE, DATA, OR OTHER INTANGIBLES RESULTING FROM: (1) THE USE OF OR THE INABILITY TO USE THE SERVICE; (2) DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO, THROUGH, OR FROM THE SERVICE; (3) ACCESS TO OR ALTERATION OF YOUR ACCOUNT, TRANSMISSIONS, OR DATA DUE TO YOUR CONDUCT, INACTION, OR NEGLIGENCE; OR (4) ANY OTHER MATTER RELATING TO THE SERVICE.</p>
                </div>

                <img src="/images/Group.svg" alt="Group avatar" />
            </div>
            <Footer />
        </Fragment>
    );
};

export default TermsAndConditions;
