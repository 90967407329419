import React, { useState } from "react";
import onestep from "../../assets/OneStepColored.svg";
import backarrow from "../../assets/backArrow.svg";
// import ReactInputVerificationCode from "react-input-verification-code";
import { Link, useNavigate } from "react-router-dom";
import { PinInput } from "react-input-pin-code";
import { toast } from "react-toastify";
import axios from "axios";
import ReactLoading from "react-loading";

const Verify = () => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [values, setValues] = React.useState(["", "", "", "", "", ""]);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const navigate = useNavigate()

  const handleVerification = async () => {
    if (values.join("") === "") {
      toast.error("Incomplete verification code");
      return;
    }
    if (values.join("").length < 6) {
      toast.error("Verification code does not match");
      return;
    }
    setVerifyLoading(true);
    try {
      const response = await axios.post(
        "https://api.ultainfinity.com/v1/users/user-account/v2/login/verify-otp",
        {
          username: sessionStorage.getItem("username"),
          login_otp: values.join(""),
        }
      );
      setVerifyLoading(false);
      console.log(response.data);
      toast.success(response.data.message);
      // navigate({pathname: "https://dashboard.ultainfinitywealthlaunchpad.com"})
    } catch (error) {
      setVerifyLoading(false);
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="container">
      <div className="back">
        <Link to="/signin">
          <img src={backarrow} alt="backarrow" /> <span>Back</span>
        </Link>
      </div>
      <div className="signin">
        <img src={onestep} alt="onestep-logo" />
        <h2>Verify Details</h2>
        <p>{sessionStorage.getItem("message")}</p>
        <PinInput
          values={values}
          onChange={(value, index, values) => setValues(values)}
          type="text"
        />

        {error && <small>{error}</small>}

        <div className="btns">
          <button onClick={handleVerification}>
            {verifyLoading ? (
              <ReactLoading type="spin" width={30} height={30} color="#fff" />
            ) : (
              "Verify Code"
            )}
          </button>
        </div>

        <div className="btns">
          <button id="recover">Resend Code</button>
        </div>
      </div>
    </div>
  );
};

export default Verify;
