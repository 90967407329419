import React from 'react'
import search from '../../assets/profile/search.svg'
import country from '../../assets/profile/country.svg'
import profile from '../../assets/profile/profile.svg'

const Contact = () => {
  return (
    <div className='profile_section'>
      <div className='profile_top'>
        <div style={{width: "100%", display: 'flex', justifyContent: 'right', alignItems: "right"}}>
          <button>Edit</button>
        </div>
        <h3>Contact Book</h3>
      </div>
      <p>Manage your personal information, including phone numbers and email addresses where you can be reached.</p>
      <div className='contact_input'>
        <p className='contact_dropdown'>
          <div>
            <label>Sort by: </label>
            <select>
              <option>A-Z</option>
            </select>
          </div>
          <div>
            <label>Groups: </label>
            <select>
              <option>All</option>
            </select>
          </div>
        </p>
        <div className='search'>
          <input placeholder='Search' type="text" />
          <img src={search} alt="search" />
        </div>
      </div>

      <div className='contacts' style={{ overflow: "hidden"}}>
        <table>
          <tbody>
            <tr>
              <td><label className='cb'><input type="checkbox" /> <span className='mark'></span></label></td>
              <td><img src={profile} alt="profile" /></td>
              <td>Ben Devis</td>
              <td>aron.matusov@gmail.com</td>
              <td><img src={country} alt="country" /> +1 741 835 60230</td>
            </tr>
            <tr>
              <td><label className='cb'><input type="checkbox" /> <span className='mark'></span></label></td>
              <td><img src={profile} alt="profile" /></td>
              <td>Ben Devis</td>
              <td>aron.matusov@gmail.com</td>
              <td><img src={country} alt="country" /> +1 741 835 60230</td>
            </tr>
            <tr>
              <td><label className='cb'><input type="checkbox" /> <span className='mark'></span></label></td>
              <td><img src={profile} alt="profile" /></td>
              <td>Ben Devis</td>
              <td>aron.matusov@gmail.com</td>
              <td><img src={country} alt="country" /> +1 741 835 60230</td>
            </tr>
            <tr>
              <td><label className='cb'><input type="checkbox" /> <span className='mark'></span></label></td>
              <td><img src={profile} alt="profile" /></td>
              <td>Ben Devis</td>
              <td>aron.matusov@gmail.com</td>
              <td><img src={country} alt="country" /> +1 741 835 60230</td>
            </tr>
            <tr>
              <td><label className='cb'><input type="checkbox" /> <span className='mark'></span></label></td>
              <td><img src={profile} alt="profile" /></td>
              <td>Ben Devis</td>
              <td>aron.matusov@gmail.com</td>
              <td><img src={country} alt="country" /> +1 741 835 60230</td>
            </tr>
            <tr>
              <td><label className='cb'><input type="checkbox" /> <span className='mark'></span></label></td>
              <td><img src={profile} alt="profile" /></td>
              <td>Ben Devis</td>
              <td>aron.matusov@gmail.com</td>
              <td><img src={country} alt="country" /> +1 741 835 60230</td>
            </tr>
            <tr>
              <td><label className='cb'><input type="checkbox" /> <span className='mark'></span></label></td>
              <td><img src={profile} alt="profile" /></td>
              <td>Ben Devis</td>
              <td>aron.matusov@gmail.com</td>
              <td><img src={country} alt="country" /> +1 741 835 60230</td>
            </tr>
            <tr>
              <td><label className='cb'><input type="checkbox" /> <span className='mark'></span></label></td>
              <td><img src={profile} alt="profile" /></td>
              <td>Ben Devis</td>
              <td>aron.matusov@gmail.com</td>
              <td><img src={country} alt="country" /> +1 741 835 60230</td>
            </tr>
            <tr>
              <td><label className='cb'><input type="checkbox" /> <span className='mark'></span></label></td>
              <td><img src={profile} alt="profile" /></td>
              <td>Ben Devis</td>
              <td>aron.matusov@gmail.com</td>
              <td><img src={country} alt="country" /> +1 741 835 60230</td>
            </tr>
            <tr>
              <td><label className='cb'><input type="checkbox" /> <span className='mark'></span></label></td>
              <td><img src={profile} alt="profile" /></td>
              <td>Ben Devis</td>
              <td>aron.matusov@gmail.com</td>
              <td><img src={country} alt="country" /> +1 741 835 60230</td>
            </tr>
          </tbody>
        </table>
            <p style={{color: "#7165E3", cursor: "pointer", fontSize: "14px", marginTop: "20px"}}>Load more <span style={{color: "#7A7B85"}}>Showing 1 to 10 of 583 contacts</span></p>
      </div>
    </div>
  )
}

export default Contact