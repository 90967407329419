import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import onestep from '../../assets/OneStep.svg'

const Verification = () => {
  useEffect(() => {
    document.getElementById("header").style.display = "none"
    setTimeout(() => {
      window.location.pathname = "/profile"
    }, 5000)
  }, [])
  return (
    <>
    <div className='container hero'>
      <img src={onestep} alt="onestep-logo" />
      <h2>Verification in progress</h2>
      <p>Your Username and ID will be sent to your
        registered number and messenger</p>
      <Link to="/profile"><button style={{marginTop: "60px"}}>Proceed Home</button></Link>
    </div>
    </>
  )
}

export default Verification