import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import Header from "./components/UI/Header";
import Hero from "./components/Hero";
import SignIn from "./components/FirstLayer/SignIn";
import Verify from "./components/FirstLayer/Verify";
import CreateID from "./components/SecondLayer/CreateID";
import VerifyID from "./components/SecondLayer/VerifyID";
import About from "./components/SecondLayer/About";
import CompleteKYC from "./components/SecondLayer/CompleteKYC";
import RecoveryQuestions from "./components/SecondLayer/RecoveryQuestions";
import AccountCreated from "./components/SecondLayer/AccountCreated";
import OneStepIDRecovery from "./components/ThirdLayer/OneStepIDRecovery";
import SuggestQuestions from "./components/ThirdLayer/SuggestQuestions";
import Verification from "./components/ThirdLayer/Verification";
import OneStepWithMessenger from "./components/ThirdLayer/OneStepWithMessenger";
import Profile from "./components/FourthLayer/Profile";
import SignInSecurity from "./components/FourthLayer/SignInSecurity";
import PersonalInfo from "./components/FourthLayer/PersonalInfo";
import Contact from "./components/FourthLayer/Contact";
import Socials from "./components/FourthLayer/Socials";
import VerifyRecovery from "./components/ThirdLayer/VerifyRecovery";
import SetRecoveryQuestions from "./components/SecondLayer/SetRecoveryQuestions";

import Privacy from "./pages/privacy/";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import Resources from "./pages/resources/Resources";
import Roadmaps from "./pages/roadmaps";

import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const [details, setDetails] = useState({
    username: "",
    phone: "",
    messenger: "telegram",
    channel: "sms",
    handler: "@ultainfinity",
    referral_code: ""
  });
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({})
  const [successful, setSuccessful] = useState(false)

  const handleCreateID = async () => {
    console.log(details)
    try {
      if (!details.username || !details.phone) {
        toast.error("Please fill all fields");
        return;
      }
      setLoading(true);
      const response = await axios.post(
        "https://api.ultainfinity.com/v1/users/user-account/v2/create",
        details
      );
      console.log(response.data);
      setResponse(response.data)
      setLoading(false);
      setSuccessful(true)
      // console.log(details)
    } catch (error) {
      console.log(error.response.data);
      toast.error(error.response.data.message)
      setLoading(false);
    }
  };

  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route exact path="/" element={<Hero />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/verify" element={<Verify />} />

          <Route
            path="/createID"
            element={
              <CreateID
                details={details}
                setDetails={setDetails}
                handleCreateID={handleCreateID}
                loading={loading}
                successful={successful}
              />
            }
          />
          <Route path="/verifyID" element={<VerifyID details={details} response={response} />} />
          <Route path="/about" element={<About />} />
          <Route path="/complete" element={<CompleteKYC />} />
          <Route path="/recovery" element={<RecoveryQuestions />} />
          <Route path="/success" element={<AccountCreated />} />
          <Route path="/set-recovery" element={<SetRecoveryQuestions />} />

          <Route path="/id-recovery" element={<OneStepIDRecovery />} />
          <Route path="/onestepmessenger" element={<OneStepWithMessenger />} />
          <Route path="/verify_recovery" element={<VerifyRecovery />} />
          <Route path="/suggest_questions" element={<SuggestQuestions />} />
          <Route path="/progress" element={<Verification />} />

          <Route path="/profile/" element={<Profile />}>
            <Route path="/profile/" element={<SignInSecurity />} />
            <Route path="/profile/personal" element={<PersonalInfo />} />
            <Route path="/profile/contact" element={<Contact />} />
            <Route path="/profile/socials" element={<Socials />} />
          </Route>

          {/* Resources, privacy, t&c routes */}
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/roadmaps" element={<Roadmaps />} />
        </Routes>
      </div>
      <ToastContainer />
    </Router>
  );
};

export default App;
