import React, { Fragment, useEffect } from "react";
// import Footer from "../Footer";
import Footer from "../UI/Footer"

const Hero = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Fragment>
            <div className="hero__section">
                <i className="bx bxs-lock-open-alt"></i>
                <h3>Privacy Policy</h3>
                <p>
                    The Ultainfinity platform requires your personal information through OneStep ID which is necessary for the performance of our obligations towards you and providing you with our services. When you use our services, you
                    consent to the collection, storage, use, disclosure and other uses of your Personal Information as described in this Privacy Policy.
                </p>
            </div>

            <div className="how__section bg-blue">
                <div className="how__top">
                    <img src="/images/Lock-circle.svg" alt="Lock circle" />
                    <h3>How does the Ultainfinity platform receive information about you?</h3>
                    <p>We receive your Personal Information from various sources: When you voluntarily provide us your personal details in order to create your OneStep ID and create your Ultainifnity wallet.</p>
                </div>

                <div className="how__type">
                    <h3>What type of information we collect?</h3>
                    <p>
                        In order to open your OneStep ID account, register for white list and Create your Ultainfinity wallet. <br /> We need to collect the following information: <br /> 1. Your name <br />
                        2. Your messenger login <br />
                        3. Your mobile no login <br />
                        4.We collect more than those three pieces of information <br />
                        5. We collect various other necessary, legally obligated and other important information, which we could not have known or needed prior.
                    </p>
                </div>
            </div>
            <div className="how__information">
                <img src="/images/lock__2.svg" alt="Lock avatar" />
                <h3>How we protect your information</h3>
                <div className="para">
                    <p>
                        We have implemented administrative, technical, and physical safeguards to help prevent unauthorized access, use, or disclosure of your personal information. Your information is stored on secure servers and isn’t
                        publicly available. We limit access of your information only to those employees or partners that need to know the information in order to enable the carrying out of the agreement between us.You need to help us
                        prevent unauthorized access to your account by protecting your password appropriately and limiting access to your account (for example, by signing off after you have finished accessing your account).
                        <br /> <br />
                        You will be solely responsible for keeping your password confidential and for all use of your password and your account, including any unauthorized use. While we seek to protect your information to ensure that it is
                        kept confidential, we cannot absolutely guarantee its security. You should be aware that there is always some risk involved in transmitting information over the internet. While we strive to protect your Personal
                        Information, we cannot ensure or warrant the security and privacy of your personal Information or other content you transmit using the service, and you do so at your own risk.
                    </p>
                </div>
            </div>


            <div className="how__updates">
                <h3>Updates to the Ultainfinity platform policy</h3>
                <p>This Privacy Policy is subject to changes from time to time, at our sole discretion. The most current version will always be posted on our website (as reflected in the "Last Updated" heading). You are advised to check for updates regularly. In the event of material changes, we will provide you with a notice. By continuing to access or use our services after any revisions become effective, you agree to be bound by the updated Privacy Policy.</p>
                <img src="/images/Group.svg" alt="Group SVG" />
            </div>
            <Footer />
        </Fragment>
    );
};

export default Hero;
