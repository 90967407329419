import React from "react";
import facebook from "../../assets/socials/facebook.svg";
import instagram from "../../assets/socials/instagram.svg";
import youtube from "../../assets/socials/youtube.svg";
import twitter from "../../assets/socials/twitter.svg";
import pininterest from "../../assets/socials/pininterest.svg";
import linkedin from "../../assets/socials/linkedin.svg";
import discord from "../../assets/socials/discord.svg";
import tiktok from "../../assets/socials/tiktok.svg";
import reddit from "../../assets/socials/reddit.svg";
import telegram from "../../assets/socials/telegram.svg";
import vk from "../../assets/socials/vk.svg";
import messenger from "../../assets/socials/messenger.svg";
import viber from "../../assets/socials/viber.svg";
import signal from "../../assets/socials/signal.svg";
import snapchat from "../../assets/socials/snapchat.svg";
import line from "../../assets/socials/line.svg";
import quora from "../../assets/socials/quora.svg";
import medium from "../../assets/socials/medium.svg";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="footer-top">
        <small style={{ color: "#666DFF " }}>Community Channels</small>
        <h4>Subscribe</h4>
        <p>
          Subscribe to our Community Channels to get regular updates, our group
          developments and free Airdrops & Bounties of Cryptocurrencies.
        </p>
      </div>
      <div className="footer-bottom">
        <div className="footer-socials">
          <a href="_">
            <img src={facebook} alt="facebook-icon" />
          </a>
          <a
            href="https://www.instagram.com/one_stepid"
            target={"_blank"}
            rel="noreferrer"
          >
            <img src={instagram} alt="instagram-icon" />
          </a>
          <a href="_">
            <img src={snapchat} alt="snapchat-icon" />
          </a>
          <a
            href="https://www.tiktok.com/@onestepid"
            target="_blank"
            rel="noreferrer"
          >
            <img src={tiktok} alt="tiktok-icon" />
          </a>
          <a
            href="https://twitter.com/onestepID"
            target="_blank"
            rel="noreferrer"
          >
            <img src={twitter} alt="twitter-icon" />
          </a>
          <a
            href="https://www.linkedin.com/in/ultainfinitywealthlaunchpad"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedin} alt="linkedin-icon" />
          </a>
          <a
            href="https://www.reddit.com/r/OneStepID"
            target="_blank"
            rel="noreferrer"
          >
            <img src={reddit} alt="reddit-icon" />
          </a>
          <a
            href="https://medium.com/@onestepid"
            target="_blank"
            rel="noreferrer"
          >
            <img src={medium} alt="medium-icon" />
          </a>
          <a
            href="https://discord.gg/jGuPTFHGRZ"
            target="_blank"
            rel="noreferrer"
          >
            <img src={discord} alt="discord-icon" />
          </a>
          <a
            href="http://www.pinterest.com/onestepid"
            target="_blank"
            rel="noreferrer"
          >
            <img src={pininterest} alt="pininterest-icon" />
          </a>
          <a
            href="https://onestepid.quora.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={quora} alt="quora-icon" />
          </a>
          <a
            href="https://youtube.com/channel/UC1NBAQW2Z1exLI38kDrrv5A"
            target="_blank"
            rel="noreferrer"
          >
            <img src={youtube} alt="youtube-icon" />
          </a>
          <img src={telegram} alt="telegram-icon" />
          <a href="_">
            <img src={vk} alt="vk-icon" />
          </a>
          <a
            href="http://m.me/109020541664806"
            target="_blank"
            rel="noreferrer"
          >
            <img src={messenger} alt="messenger-icon" />
          </a>
          <a href="_">
            <img src={viber} alt="viber-icon" />
          </a>
          <a href="_">
            <img src={line} alt="line-icon" />
          </a>
          <a href="https://signal.org/install" target="_blank" rel="noreferrer">
            <img src={signal} alt="signal-icon" />
          </a>
        </div>
        <div className="p-c">
          <Link to="/privacy" style={{ color: "#fff" }}>
            Privacy
          </Link>
          <Link to="/terms-conditions" style={{ color: "#fff" }}>
            Terms
          </Link>
          <Link to="/resources" style={{ color: "#fff" }}>
            Resources
          </Link>
          <Link to="/roadmaps" style={{ color: "#fff" }}>
            Roadmaps
          </Link>
        </div>
        <div className="footer-bottom-t">
          <p>
            Important to note, trading in cryptocurrencies and in particular
            synthetic assets comes with risks. You can lose all your investment.
            Furthermore, trading in cryptocurrencies generally and trading on
            ULTAINFINITY Exchange UTTA, DIVIT or any assets on our platforms are
            not regulated by any Financial Authority.
          </p>
          <p>© Ultainfinity Wealth Launchpad 2022</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
