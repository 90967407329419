import React from 'react'
import { Link } from 'react-router-dom'
import onestep from '../../assets/OneStepColored.svg'
import backarrow from '../../assets/backArrow.svg'


const CompleteKYC = () => {

  return (
    <div className='container'>
        <div className='back'>
            <Link to="/complete"><img src={backarrow} alt="backarrow" /> <span>Back</span></Link>
        </div>
        <div className='signin createID'>
        <img src={onestep} alt="onestep-logo" />
        <h2>Complete KYC</h2>
        <p>Kindly provide these details for your KYC
Verification</p>

        <div className='create_input'>
            <label>First & Last Name</label>
            <input type="text" placeholder='John Doe' />
        </div>

        <div className='create_input'>
            <label>Select Country / Region</label>
            <input type="text" placeholder='South Somewhere' />
        </div>

        <div className='create_input'>
            <label>Full Home Address</label>
            <input type="text" placeholder='20, Alkama Street, London' />
        </div>

        <div className='create_input'>
            <label>Birthday</label>
            <input type="date" placeholder='20, Alkama Street, London' />
        </div>
       
        <div className='btns'>
            <Link to="/recovery"><button>Continue</button></Link>
        </div>
        </div>
    </div>
  )
}

export default CompleteKYC