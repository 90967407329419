import React, { useState } from 'react'
import onestep from '../../assets/OneStepColored.svg'
import backarrow from '../../assets/backArrow.svg'
import ReactInputVerificationCode from "react-input-verification-code";
import { Link } from 'react-router-dom';

const VerifyRecovery = () => {
  const [code, setCode] = useState('')
  const [error, setError] = useState("")

  const handleVerification = ()  => {
    console.log(code)
    if(code !== "12345") setError("Invalid code")
  }

  return (
    <div className='container'>
      <div className='back'>
        <Link to="/onestepmessenger"><img src={backarrow} alt="backarrow" /> <span>Back</span></Link>
      </div>
    <div className='signin'>
        <img src={onestep} alt="onestep-logo" />
        <h2>Verify Details</h2>
        <p>We have sent an OTP code to @Lek....w
          Input the OTP code to complete your verification</p>
        <ReactInputVerificationCode 
          className="verification"
          onCompleted={handleVerification}
          autoFocus={true} 
          placeholder="-" 
          value={code}
          length={5}
          onChange={(newValue) => setCode(newValue) }
          />

        {error && <small>{error}</small>}


        <div className='btns'>
            <button id="recover">Resend Code</button>
        </div>
    </div>
    </div>
  )
}

export default VerifyRecovery