import React, { useState, useEffect } from 'react'
import question from '../../assets/question.svg'
import { Link } from 'react-router-dom'
import menu from '../../assets/menu.svg'
import profile from '../../assets/profile.svg'

const Header = () => {

  const [toggleMenu, setToggleMenu] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [activeTab, setActiveTab] = useState(0)

  const toggleSidebar = (value) => {
    setActiveTab(value)
    setToggleMenu(false);
  }

  const toggleNav = () =>{
    setToggleMenu(!toggleMenu);
  }

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', changeWidth);

    return () => {
      window.removeEventListener('resize', changeWidth)
    }
  }, [])

  return (
    <header id="header">
      <div className='logo'><Link to="/">OneStep ID</Link></div>
      <div className='header_btns'>
        {
          (toggleMenu || screenWidth > 767) && 
          ((window.location.pathname === "/profile" || window.location.pathname === "/profile/personal" || window.location.pathname === "/profile/socials" || window.location.pathname === "/profile/contact") ?
          <div className='small_sidebar signout'>
            <div className='signin_bar'>
              <img src={profile} alt="profile_pic" />
              <p id="name"><strong>John Doe</strong></p>
              <p id="id">ID239892380</p>

              <Link to="/profile"><p className={` ${activeTab === 0 ? "active_tab" : ""}`} data-tab="0" onClick={() => toggleSidebar(0)}>Sign-In and Security</p></Link>
              <Link to="/profile/personal"><p className={` ${activeTab === 1 ? "active_tab" : ""}`} data-tab="1" onClick={() => toggleSidebar(1)}>Personal Information</p></Link>
              <Link to="/profile/contact"><p className={` ${activeTab === 2 ? "active_tab" : ""}`} data-tab="2" onClick={() => toggleSidebar(2)}>Contact Book</p></Link>
              <Link to="/profile/socials"><p className={` ${activeTab === 3 ? "active_tab" : ""}`} data-tab="3" onClick={() => toggleSidebar(3)}>Social Media Accounts</p></Link>
          </div>
            <Link to='/'><button id="signout_btn" onClick={() => setToggleMenu(false)}>Sign Out</button></Link>
            <Link to="/about"><img onClick={() => setToggleMenu(false)} src={question} alt="question-icon" /></Link>
          </div> : 
          <span>
            <div className='small_sidebar'>
              <Link to='/signin'><button onClick={() => setToggleMenu(false)}>Sign In</button></Link>
              <Link to="/createID"><button onClick={() => setToggleMenu(false)}>Create OneStep ID</button></Link>
              <Link to="/about"><img onClick={() => setToggleMenu(false)} src={question} alt="question-icon" /></Link>
            </div>
          </span>)
        }
        <img className='menu' onClick={toggleNav} src={menu} alt="menu-icon" />
      </div>
    </header>
  )
}

export default Header