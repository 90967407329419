import React from 'react'
import Release from './Release'
import "./roadmap__styles.css"

const Hero = () => {
  return (
    <div className="roadmap__hero">
      <h5>World’s Best</h5>
      <h1>Ultainfinity Global Group(UGG) Roadmap</h1>
      <Release />
    </div>
  )
}

export default Hero