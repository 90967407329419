import React from 'react'
import { Link } from 'react-router-dom'
import onestep from '../../assets/OneStepColored.svg'
import backarrow from '../../assets/backArrow.svg'

const SuggestQuestions = () => {
  return (
    <div className='container'>
        <div className='back'>
            <Link to="/complete"><img src={backarrow} alt="backarrow" /> <span>Back</span></Link>
        </div>
        <div className='signin createID'>
        <img src={onestep} alt="onestep-logo" />
        <h2 style={{marginBottom: "100px"}}>Suggest Questions</h2>

        <div className='create_input'>
            <label>What is your mother’s maiden name?</label>
            <input type="text" placeholder='Mary Sonya' />
        </div>

        <div className='create_input'>
            <label>What is your pet name?</label>
            <input type="text" placeholder='Lux' />
        </div>

        <div className='create_input'>
            <label>Where were you born?</label>
            <input type="text" placeholder='Idina' />
        </div>

       
        <div className='btns'>
            <Link to="/progress"><button>Next</button></Link>
        </div>
        </div>
    </div>
  )
}

export default SuggestQuestions