import React from 'react'
import { Link } from 'react-router-dom'
import map from '../assets/map.svg'

const Details = () => {
  return (
    <div className='container details'>
        <div>
          <h2>You are in control</h2>
          <p>Experience the uniqueness of one-step sign ups and identification at these various
          global platforms</p>
          <Link to="/about"><button>Learn More</button></Link>
        </div>
        <img src={map} alt="map" />
    </div>
  )
}

export default Details