import React from 'react'
import web from '../../assets/profile/web.svg'
import password from '../../assets/profile/password.svg'


const SignInSecurity = () => {
  return (
    <div className='profile_section'>
      <div className='profile_top'>
        <div style={{width: "100%", display: 'flex', justifyContent: 'right', alignItems: "right"}}>
          <button>Edit</button>
        </div>
        <h3>Sign-In and Security</h3>
      </div>
      <p>Manage settings related to signing in to your account, account security, as well as how to recover your data when you’re having trouble signing in.</p>

      <div className='card_container'>
        <div className='card'>
          <div>
            <h4>One Step ID</h4>
            <p>ID239892380</p>
          </div>
          <img src={web} alt="web" />
        </div>

        <div className='card'>
          <div>
            <h4>Password</h4>
            <p>Last updated December, 2021</p>
          </div>
          <img src={password} alt="web" />
        </div>
      </div>
    </div>
  )
}

export default SignInSecurity