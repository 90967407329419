import React from 'react'
import instagram from '../../assets/profile/instagram.svg'
import facebook from '../../assets/profile/facebook.svg'
import telegram from '../../assets/profile/telegram.svg'
import tiktok from '../../assets/profile/tiktok.svg'
import twitter from '../../assets/profile/twitter.svg'
import linkedin from '../../assets/profile/linkedin.svg'
import whatsapp from '../../assets/profile/whatsapp.svg'


const Socials = () => {
  return (
    <div className='profile_section'>
      <div className='profile_top'>
        <div style={{width: "100%", display: 'flex', justifyContent: 'right', alignItems: "right"}}>
          <button>Edit</button>
        </div>
        <h3>Social Media Account</h3>
      </div>
      <p>Manage your personal information, including phone numbers and email addresses where you can be reached.</p>

      <div className='card_container'>
        <div className='card'>
          <div>
            <h4>Instagram</h4>
            <p>@breanneschinner</p>
            <p>+1 741 835 60230</p>
            <p>Breannerschinner@gmail.com</p>
          </div>
          <img src={instagram} alt="web" />
        </div>
        
        <div className='card'>
          <div>
            <h4>Twitter</h4>
            <p>@breanneschinner</p>
            <p>+1 741 835 60230</p>
            <p>Breannerschinner@gmail.com</p>
          </div>
          <img src={twitter} alt="web" />
        </div>

        <div className='card'>
          <div>
            <h4>Facebook</h4>
            <p>@breanneschinner</p>
            <p>+1 741 835 60230</p>
            <p>Breannerschinner@gmail.com</p>
          </div>
          <img src={facebook} alt="web" />
        </div>

        <div className='card'>
          <div>
            <h4>LinkedIn</h4>
            <p>@breanneschinner</p>
            <p>+1 741 835 60230</p>
            <p>Breannerschinner@gmail.com</p>
          </div>
          <img src={linkedin} alt="web" />
        </div>

        <div className='card'>
          <div>
            <h4>Telegram</h4>
            <p>@breanneschinner</p>
            <p>+1 741 835 60230</p>
            <p>Breannerschinner@gmail.com</p>
          </div>
          <img src={telegram} alt="web" />
        </div>

        <div className='card'>
          <div>
            <h4>Whatsapp</h4>
            <p>+1 741 835 60230</p>
            <p>Breannerschinner@gmail.com</p>
          </div>
          <img src={whatsapp} alt="web" />
        </div>

        <div className='card'>
          <div>
            <h4>Tiktok</h4>
            <p>@breanneschinner</p>
            <p>+1 741 835 60230</p>
            <p>Breannerschinner@gmail.com</p>
          </div>
          <img src={tiktok} alt="web" />
        </div>

      </div>
    </div>
  )
}

export default Socials