import React from "react";
import { Link } from "react-router-dom";
import onestep from "../../assets/OneStepColored.svg";
import backarrow from "../../assets/backArrow.svg";
import whatsapp from "../../assets/color-social/whatsapp.svg"
import telegram from "../../assets/color-social/telegram.svg"
import messenger from "../../assets/color-social/messenger.svg"
import viber from "../../assets/color-social/viber.svg"
import signal from "../../assets/color-social/signal.svg"
import discord from "../../assets/color-social/discord.svg"
import snapchat from "../../assets/color-social/snapchat.svg"
import skype from "../../assets/color-social/skype.svg"
import line from "../../assets/color-social/line.svg"
import g3 from "../../assets/color-social/g3.svg"

const OneStepWithMessenger = () => {
  return (
    <div className="container">
      <div className="back">
        <Link to="/id-recovery">
          <img src={backarrow} alt="backarrow" /> <span>Back</span>
        </Link>
      </div>
      <div className="signin recoveryID">
        <img src={onestep} alt="onestep-logo" />
        <h2>OneStep ID Recovery</h2>
        <p>Choose Messenger</p>

        <div className="recovery_messenger">
          <Link to="/verify_recovery"><img src={whatsapp} alt="whatsapp" /></Link>
          <Link to="/verify_recovery"><img src={telegram} alt="telegram" /></Link>
          <Link to="/verify_recovery"><img src={messenger} alt="messenger" /></Link>
          <Link to="/verify_recovery"><img src={viber} alt="viber" /></Link>
          <Link to="/verify_recovery"><img src={signal} alt="signal" /></Link>
          <Link to="/verify_recovery"><img src={discord} alt="discord" /></Link>
          <Link to="/verify_recovery"><img src={snapchat} alt="snapchat" /></Link>
          <Link to="/verify_recovery"><img src={skype} alt="skype" /></Link>
          <Link to="/verify_recovery"><img src={line} alt="line" /></Link>
          <Link to="/verify_recovery"><img src={g3} alt="g3" /></Link>
        </div>
      </div>
    </div>
  );
};

export default OneStepWithMessenger;
