import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import onestep from "../../assets/OneStepColored.svg";
import backarrow from "../../assets/backArrow.svg";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import ReactLoading from "react-loading"

const CreateID = ({ details, setDetails, handleCreateID, loading, successful }) => {
  const [verifyBy, setVerifyBy] = useState("");
  const navigate = useNavigate()

  useEffect(() => {
    if (verifyBy === "SMS") {
      document.getElementById("messenger").style.display = "none";
    } else {
      document.getElementById("messenger").style.display = "block";
    }
  }, [verifyBy]);

  useEffect(() => {
    if (successful) {
        navigate("/verifyID")
    }
  }, [successful])

  return (
    <div className="container">
      <div className="back">
        <Link to="/">
          <img src={backarrow} alt="backarrow" /> <span>Back</span>
        </Link>
      </div>
      <div className="signin createID">
        <img src={onestep} alt="onestep-logo" className="signin__image" />
        <h2>Create OneStep ID</h2>
        <p>
          Already have an account?{" "}
          <Link to="/signin">
            <span style={{ cursor: "pointer", color: "#9757D7" }}>Sign in</span>
          </Link>
        </p>

        <div className="create_input">
          <label>Choose a Username</label>
          <input
            type="text"
            placeholder="John Doe"
            value={details.username}
            onChange={(e) =>
              setDetails({ ...details, username: e.target.value })
            }
          />
        </div>

        <div className="create_input">
          <label>Phone Number</label>
          <PhoneInput
            placeholder="Enter phone number"
            defaultCountry="US"
            id="phone"
            className="phone__number"
            value={details.phone}
            onChange={(e) => setDetails({ ...details, phone: e })}
          />
        </div>

        {/* <div className="create_input">
          <label>Choose a messenger</label>
         
          <select
            name="messenger"
            value={details.messenger}
            onChange={(e) =>
              setDetails({ ...details, messenger: e.target.value })
            }
          >
            <option selected hidden="Whatsapp">Select messenger</option>
            <option value="Whatsapp">WhatsApp</option>
            <option value="twitter">Twitter</option>
            <option value="linkedin">Linkedin</option>
            <option value="telegram">Telegram</option>
          </select>
        </div> */}

        <div className="create_input">
          <label>Referral Code (Optional)</label>
          <input
            type="text"
            // placeholder="John Doe"
            value={details.referral_code}
            onChange={(e) =>
              setDetails({ ...details, referral_code: e.target.value })
            }
          />
        </div>

        {/* <div onChange={(e) => setVerifyBy(e.target.value)}>
          <label>Verify Using</label>
          <div className="first_input">
            <input
              defaultChecked
              type="radio"
              name="verify"
              value="Messenger"
            />{" "}
            <span>Messenger</span>
            <input type="radio" name="verify" value="SMS" /> <span>SMS</span>
          </div>
        </div> */}
        <div id="messenger">
          {/* <label>Choose Messenger</label>
          <select>
            <option>Facebook</option>
            <option>Twitter</option>
          </select> */}
        </div>

        <div className="btns">
          <button onClick={handleCreateID}>
            {loading ? <ReactLoading type="spin" width={30} height={30} color="#fff" /> : "Continue"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateID;
