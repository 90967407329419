import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import onestep from "../../assets/OneStepColored.svg";
import backarrow from "../../assets/backArrow.svg";
import axios from "axios";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";

const SignIn = () => {
  const [verifyBy, setVerifyBy] = useState("");
  const [details, setDetails] = useState({
    username: "",
    channel: "sms",
    handler: "@ultainfinity",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  //   console.log(details.channel)

  const handleVerifyID = async () => {
    if (!details.username) {
      toast.error("Incomplete details");
      return;
    }
    setLoading(true);

    try {
      const response = await axios.post(
        "https://api.ultainfinity.com/v1/users/user-account/v2/login",
        details
      );
      console.log(response.data);
      sessionStorage.setItem("message", response.data.message);
      // sessionStorage.setItem("login_otp", response.data.data.login_otp);
      sessionStorage.setItem("username", details.username);
      navigate("/verify");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.response);
      toast.error(error.response);
    }
  };

  useEffect(() => {
    if (verifyBy === "SMS") {
      document.getElementById("messenger").style.display = "none";
    } else {
      document.getElementById("messenger").style.display = "block";
    }
  }, [verifyBy]);

  return (
    <div className="container">
      <div className="back">
        <Link to="/">
          <img src={backarrow} alt="backarrow" /> <span>Back</span>
        </Link>
      </div>
      <div className="signin">
        <img src={onestep} alt="onestep-logo" />
        <h2>OneStep Sign In</h2>
        <p>
          Don’t have an account?{" "}
          <Link to="/createID">
            <span style={{ cursor: "pointer", color: "#9757D7" }}>
              Create account
            </span>
          </Link>
        </p>
        <input
          type="text"
          placeholder="Enter Onestep Username"
          value={details.username}
          onChange={(e) => setDetails({ ...details, username: e.target.value })}
        />

        <div className="sign">
          {/* <div onChange={(e) => setVerifyBy(e.target.value)}>
            <label>Verify Using</label>
            <div className="first_input">
              <input
                defaultChecked
                type="radio"
                name="verify"
                value="Messenger"
                onChange={(e) =>
                  setDetails({ ...details, channel: e.target.value })
                }
              />{" "}
              <span>Messenger</span>
              <input
                type="radio"
                name="verify"
                value="SMS"
                onChange={(e) =>
                  setDetails({ ...details, channel: e.target.value })
                }
              />{" "}
              <span>SMS</span>
            </div>
          </div> */}
          <div id="messenger">
            {/* <label>Choose Messenger</label>
            <select>
              <option>Facebook</option>
              <option>Twitter</option>
            </select> */}
          </div>
        </div>
        <div className="btns">
          <button onClick={handleVerifyID}>
            {loading ? (
              <ReactLoading type="spin" width={30} height={30} color="#fff" />
            ) : (
              "Verify"
            )}
          </button>

          <Link to="/id-recovery">
            <button id="recover">Recover OneStep ID</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
